<template>
  <vx-card title="Product Team">
    <div class="vx-input-group flex">
      <vs-button
        class="mt-2 mr-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
        >Create</vs-button
      >
      <vs-button
        class="mt-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-upload"
        @click="handleImport()"
        >Import</vs-button
      >
      <vs-button
        class="mt-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-download"
        @click="ExportForm()"
        >Export</vs-button
      >
    </div>

    <div class="vx-row">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <div class="vx-col sm:w-1/1 w-full mb-base">
          <data-table
            @selected-items="handleSelectedItems"
            :territoryID="this.selectedTerritory.id"
            :baseUrl="this.baseUrl"
            :detail="this.detail"
          ></data-table>
        </div>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[detail ? detailShow : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- <FormPlan @close="handleClose" :baseUrl="this.baseUrl"></FormPlan> -->
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    DataTable,
    Datepicker,
  },
  data() {
    return {
      uploadReady: true,
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      baseUrl: "/api/sfa/v1/product-teams",
      optionTerritory: [],
      selectedTerritory: {},
      selectedExport: null,
    };
  },
  methods: {
    // handleSubmit() {
    //   var file = this.$refs.file.files[0];
    //   if (file == null) {
    //     this.$vs.notify({
    //       color: "danger",
    //       title: "Form Validation",
    //       text: "File is required",
    //       position: "top-right",
    //       iconPack: "feather",
    //       icon: "icon-x-circle",
    //     });
    //   } else {
    //     this.formData = new FormData();
    //     this.formData.append("file", file);
    //     this.$vs.loading();
    //     this.$http
    //       .post(
    //         "/api/sfa/v1/product-teams/import-product-teams",
    //         this.formData,
    //         {
    //           headers: {
    //             "Content-Type": "multipart/form-data",
    //           },
    //         }
    //       )
    //       .then((resp) => {
    //         this.$vs.loading.close();
    //         if (resp.status == "OK") {
    //           this.$vs.notify({
    //             color: "success",
    //             title: "Success",
    //             text: resp.message,
    //             position: "top-right",
    //             iconPack: "feather",
    //             icon: "icon-x-circle",
    //           });
    //           this.uploadReady = false;
    //           this.$nextTick(() => {
    //             this.uploadReady = true;
    //           });
    //         } else {
    //           this.$vs.notify({
    //             color: "danger",
    //             title: "Error",
    //             text: resp.message,
    //             position: "top-right",
    //             iconPack: "feather",
    //             icon: "icon-x-circle",
    //           });
    //         }
    //       })
    //       .catch((success) => {
    //         this.$vs.notify({
    //           color: "success",
    //           title: "success",
    //           text: success,
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       });
    //   }
    // },
    handleCreate() {
      this.$router.push({
        name: "product-teams-create",
        // params: { id: id },
      });
    },
    handleImport() {
      this.$router.push({
        name: "product-teams-import",
        // params: { id: id },
      });
    },

    handleOpen() {
      this.detail = true;
    },
    handleSelectedItems(selectedItems) {
      console.log(selectedItems);
      this.selectedExport = selectedItems;
    },
    ExportForm() {
      const id = this.$route.params.id;
      var fileTitle = "PRODUCT_TEAM_EXPORT_";
      // this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-teams/export", {
          responseType: "arraybuffer",
          params: {
            selectedExport: this.selectedExport.join(", "),
          },
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
          // this.$vs.loading.close();
        });
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
  },
  mounted() {
    this.getTerritory();
  },
  watch: {
    selectedTerritory() {
      this.getZone();
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
